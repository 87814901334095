import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermsModalComponent } from '../../../../../../components/terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getEnvironment } from '../../../../../../environment/environment';
import { XtrasService } from '../../../../../../services/xtras.service';

@Component({
  selector: 'app-footer-3',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TermsModalComponent
  ]
})


export class Footer3Component implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;
  env = getEnvironment();

  line1Images:any[] = [];

  line1?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };
  line2?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };
  line3?: { title: string, titlePos: 'left'|'right'|'center', list: any[] };

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public xServices: XtrasService,
  ) {
    if(this.env.type == 'wilstermann') {
      this.line1 = {
        title: 'Soporte Todotix - Abonos',
        titlePos: 'left',
        list: [
          { url: 'tel:+59169777799', target: '_blank', icon: 'fa-light fa-phone', name: 'Celular: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'https://api.whatsapp.com/send?phone=59169777799', target: '_blank', icon: 'fa-brands fa-whatsapp', name: 'WhatsApp: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'mailto:soporte@todotix.com', target: '_blank', icon: 'fa-light fa-envelope', name: 'E-mail: soporte@todotix.com', typeIcon: 'icon', action: undefined, textColor: undefined },
        ]
      }
      this.line2 = {
        title: 'Páginas',
        titlePos: 'left',
        list: [
          { url: '/shop', target: undefined, icon: undefined, name: 'Abonos', typeIcon: undefined, action: undefined , textColor: undefined},
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: '/pending-payments', target: undefined, icon: undefined, name: 'Mis Pagos', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTerms(), textColor: undefined },
        ]
      }
      this.line3 = {
        title: 'Redes sociales',
        titlePos: 'left',
        list: [
          { url: 'https://www.facebook.com/WilstermannClubDeportivo/', target: '_blank', icon: 'fa-brands fa-facebook-f', name: 'Facebook', typeIcon: 'icon', action: undefined, textColor: '#4267b2' },
          { url: 'https://twitter.com/WilstermannCD', target: '_blank', icon: 'fa-brands fa-twitter', name: 'Twitter', typeIcon: 'icon', action: undefined, textColor: '#00adee' },
          { url: 'https://www.instagram.com/clubdeportivojorgewilstermann', target: '_blank', icon: 'fa-instagram', name: 'Instagram', typeIcon: 'icon', action: undefined, textColor: '#9f47b4' },
        ]
      }
    } else if(this.env.type == 'san-jose') {
      this.line1 = {
        title: 'Soporte Todotix - Abonos',
        titlePos: 'left',
        list: [
          { url: 'tel:+59169777799', target: '_blank', icon: 'fa-light fa-phone', name: 'Celular: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'https://api.whatsapp.com/send?phone=59169777799', target: '_blank', icon: 'fa-brands fa-whatsapp', name: 'WhatsApp: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'mailto:soporte@todotix.com', target: '_blank', icon: 'fa-light fa-envelope', name: 'E-mail: soporte@todotix.com', typeIcon: 'icon', action: undefined, textColor: undefined },
        ]
      }
      this.line2 = {
        title: 'Páginas',
        titlePos: 'left',
        list: [
          { url: '/shop', target: undefined, icon: undefined, name: 'Abonos', typeIcon: undefined, action: undefined , textColor: undefined},
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: '/pending-payments', target: undefined, icon: undefined, name: 'Mis Pagos', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTerms(), textColor: undefined },
        ]
      }
      this.line3 = {
        title: 'Redes sociales',
        titlePos: 'left',
        list: [
          { url: 'https://www.facebook.com/GVSANJOSE/?locale=es_LA', target: '_blank', icon: 'fa-brands fa-facebook-f', name: 'Facebook', typeIcon: 'icon', action: undefined, textColor: '#4267b2' },
          { url: 'https://www.instagram.com/gvsanjose/', target: '_blank', icon: 'fa-brands fa-instagram', name: 'Instagram', typeIcon: 'icon', action: undefined, textColor: '#9f47b4' },
          { url: 'https://x.com/gvcdsanjose', target: '_blank', icon: 'fa-brands fa-twitter', name: 'Twitter', typeIcon: 'icon', action: undefined, textColor: '#00adee' },
        ]
      }
    } else if(this.env.type == 'oriente-petrolero') {
      this.line1 = {
        title: 'Soporte Todotix - Abonos',
        titlePos: 'left',
        list: [
          { url: 'tel:+59169777799', target: '_blank', icon: 'fa-light fa-phone', name: 'Celular: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'https://api.whatsapp.com/send?phone=59169777799', target: '_blank', icon: 'fa-brands fa-whatsapp', name: 'WhatsApp: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'mailto:soporte@todotix.com', target: '_blank', icon: 'fa-light fa-envelope', name: 'E-mail: soporte@todotix.com', typeIcon: 'icon', action: undefined, textColor: undefined },
        ]
      }
      this.line2 = {
        title: 'Páginas',
        titlePos: 'left',
        list: [
          { url: '/shop', target: undefined, icon: undefined, name: 'Abonos', typeIcon: undefined, action: undefined , textColor: undefined},
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: '/pending-payments', target: undefined, icon: undefined, name: 'Mis Pagos', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTerms(), textColor: undefined },
        ]
      }
      this.line3 = {
        title: 'Redes sociales',
        titlePos: 'left',
        list: [
          { url: 'https://www.facebook.com/cdopetrolero/', target: '_blank', icon: 'fa-brands fa-facebook-f', name: 'Facebook', typeIcon: 'icon', action: undefined, textColor: '#4267b2' },
          { url: 'https://www.instagram.com/cdopetrolero', target: '_blank', icon: 'fa-brands fa-instagram', name: 'Instagram', typeIcon: 'icon', action: undefined, textColor: '#9f47b4' },
          { url: 'https://x.com/cdopetrolero', target: '_blank', icon: 'fa-brands fa-twitter', name: 'Twitter', typeIcon: 'icon', action: undefined, textColor: '#00adee' },
        ]
      }
    } else if(this.env.type == 'ironcamba') {
      this.line1 = {
        title: 'Abonos',
        titlePos: 'left',
        list: [
          { url: 'tel:+59169777799', target: '_blank', icon: 'fa-light fa-phone', name: 'Celular: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'https://api.whatsapp.com/send?phone=59169777799', target: '_blank', icon: 'fa-brands fa-whatsapp', name: 'WhatsApp: 69777799', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'mailto:soporte@todotix.com', target: '_blank', icon: 'fa-light fa-envelope', name: 'E-mail: soporte@todotix.com', typeIcon: 'icon', action: undefined, textColor: undefined },
        ]
      }
      this.line2 = {
        title: 'Páginas',
        titlePos: 'left',
        list: [
          { url: '/shop', target: undefined, icon: undefined, name: 'Abonos', typeIcon: undefined, action: undefined , textColor: undefined},
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: '/pending-payments', target: undefined, icon: undefined, name: 'Mis Pagos', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTerms(), textColor: undefined },
        ]
      }
      this.line3 = {
        title: 'Redes sociales',
        titlePos: 'left',
        list: [
          { url: 'https://www.facebook.com/cdopetrolero/', target: '_blank', icon: 'fa-brands fa-facebook-f', name: 'Facebook', typeIcon: 'icon', action: undefined, textColor: '#4267b2' },
          { url: 'https://www.instagram.com/cdopetrolero', target: '_blank', icon: 'fa-brands fa-instagram', name: 'Instagram', typeIcon: 'icon', action: undefined, textColor: '#9f47b4' },
          { url: 'https://x.com/cdopetrolero', target: '_blank', icon: 'fa-brands fa-twitter', name: 'Twitter', typeIcon: 'icon', action: undefined, textColor: '#00adee' },
        ]
      }
    } else if(this.env.type == 'race-ligalapaz') {
      this.line1 = {
        title: 'Soporte',
        titlePos: 'left',
        list: [
          { url: 'tel:+59177777777', target: '_blank', icon: 'fa-light fa-phone', name: 'Celular: 77777777', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'https://api.whatsapp.com/send?phone=59177777777', target: '_blank', icon: 'fa-brands fa-whatsapp', name: 'WhatsApp: 77777777', typeIcon: 'icon', action: undefined, textColor: undefined },
          { url: 'mailto:soporte@ligalapaz.com', target: '_blank', icon: 'fa-light fa-envelope', name: 'E-mail: soporte@ligalapaz.com', typeIcon: 'icon', action: undefined, textColor: undefined },
        ]
      }
      this.line2 = {
        title: 'Páginas',
        titlePos: 'left',
        list: [
          { url: '/shop', target: undefined, icon: undefined, name: 'Carreras', typeIcon: undefined, action: undefined , textColor: undefined},
          { url: '/account', target: undefined, icon: undefined, name: 'Mi cuenta', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: '/pending-payments', target: undefined, icon: undefined, name: 'Mis Pagos', typeIcon: undefined, action: undefined, textColor: undefined },
          { url: undefined, target: undefined, icon: undefined, name: 'Términos y condiciones', typeIcon: undefined, action: () => this.openTerms(), textColor: undefined },
        ]
      }
      this.line3 = {
        title: 'Redes sociales',
        titlePos: 'left',
        list: [
          { url: 'https://www.facebook.com/profile.php?id=100076316911494&mibextid=ZbWKwL', target: '_blank', icon: 'fa-brands fa-facebook-f', name: 'Facebook', typeIcon: 'icon', action: undefined, textColor: '#4267b2' },
          { url: 'https://www.instagram.com/ligalapaz10k?igsh=MXJrcWt2d3hvNWgzbA==', target: '_blank', icon: 'fa-brands fa-instagram', name: 'Instagram', typeIcon: 'icon', action: undefined, textColor: '#9f47b4' },
          { url: 'https://runsignup.com/Race/BO/LaPaz/LigaLaPaz', target: '_blank', icon: 'far fa-globe', name: 'Sitio Web', typeIcon: 'icon', action: undefined, textColor: '#00adee' },
        ]
      }

      this.line1Images = [
        { url: undefined, img: `assets/img/footer/line-1/alianza-seguros.png`, name: 'alianza-seguros' },
        { url: undefined, img: `assets/img/footer/line-1/bago.png`, name: 'bago' },
        { url: undefined, img: `assets/img/footer/line-1/banco-ecofuturo.png`, name: 'banco-ecofuturo' },
        { url: undefined, img: `assets/img/footer/line-1/coflenac-gel.png`, name: 'coflenac-gel' },
        { url: undefined, img: `assets/img/footer/line-1/colnatur.png`, name: 'colnatur' },
        { url: undefined, img: `assets/img/footer/line-1/font-activ.png`, name: 'font-activ' },
        { url: undefined, img: `assets/img/footer/line-1/gob-lapaz.png`, name: 'gob-lapaz' },
        { url: undefined, img: `assets/img/footer/line-1/heliocare.png`, name: 'heliocare' },
        { url: undefined, img: `assets/img/footer/line-1/meds.png`, name: 'meds' },
        { url: undefined, img: `assets/img/footer/line-1/muevete.png`, name: 'muevete' },
        { url: undefined, img: `assets/img/footer/line-1/mylaps.png`, name: 'mylaps' },
        { url: undefined, img: `assets/img/footer/line-1/powerade.png`, name: 'powerade' },
        { url: undefined, img: `assets/img/footer/line-1/sutisana.png`, name: 'sutisana' },
      ];
    }
  }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  openTerms() {
    const dialogRef = this.dialog.open(TermsModalComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);

    });
  }

}
